import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'

const Button = ({
    type,
    variant,
    size,
    icon,
    onClick,
    isSubmitting,
    isSubmitted,
    className,
    disabled,
    children
}) => {
    const classes = [
        'btn',
        ...(variant ? [`btn-${variant}`] : ['btn-primary']),
        ...(size ? [`btn-${size}`] : []),
        ...(icon ? [`btn-${icon}`] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <button
            className={concatenatedClasses}
            type={type}
            disabled={isSubmitting || isSubmitted || disabled}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

Button.propTypes = {
    /**
     * Button type
     */
    type: PropTypes.oneOf(['button', 'submit']),
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['primary', 'secondary', 'inverse', 'feature']),
    /**
     * Optional set alternate button size
     */
    size: PropTypes.oneOf(['sm', 'lg']),
    /**
     * Optional set button icon
     */
    icon: PropTypes.oneOf(['arrow', 'chevron', 'chevron-back', 'external']),
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Optional disabled condition
     */
    disabled: PropTypes.bool,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired,
    /**
     * onClick function
     */
    onClick: PropTypes.func
}

Button.defaultProps = {
    type: 'button',
    variant: 'primary',
    isSubmitting: false,
    isSubmitted: false,
    children: 'Button title'
}

export default Button
